import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const PrivacyRequest = () => {
	useEffect(() => {
		const typeFormScript = document.createElement('script');

		typeFormScript.innerHTML = `(function () { var qs, js, q, s, d = document, gi = d.getElementById, ce = d.createElement, gt = d.getElementsByTagName, id = "typef_orm_share", b = "https://embed.typeform.com/"; if (!gi.call(d, id)) { js = ce.call(d, "script"); js.id = id; js.src = b + "embed.js"; q = gt.call(d, "script")[0]; q.parentNode.insertBefore(js, q) } })()`;
		document.body.appendChild(typeFormScript);

		return () => {
			document.body.removeChild(typeFormScript);
		};
	}, []);

	return (
		<Layout>
			<Seo
				title="Privacy Request - hear.com"
				description="Form to submit a privacy request. California residents only."
			/>
			<div className="o-wrapper">
				<div className="o-row-1p5 o-row-2-d">
					<h1 className="c-privacy-headline">Privacy Request</h1>
				</div>
				<div className="o-row-2">
					<p className="c-privacy-copy">
						Please click on the button below to submit a privacy request. California residents only.
					</p>
				</div>
				<div className="o-row-5" style={{ textAlign: 'center', marginTop: '25px' }}>
					<a
						className="typeform-share c-cta c-cta--brand"
						href="https://form.typeform.com/to/BebrJaoX"
						data-mode="popup"
						target="_blank"
						rel="noreferrer"
						style={{ maxWidth: '370px' }}
					>
						Open Privacy Request Form
					</a>
				</div>
			</div>
		</Layout>
	);
};

export default PrivacyRequest;
